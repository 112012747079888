<template>
    <div class="page"  v-loading="uploading">
        <div class="hzynav w-1350">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{path: '/wmzhfw?hzy_id='+$route.query.p_id}">外贸综合服务</el-breadcrumb-item>
                <el-breadcrumb-item :to="{path: '/service_list?p_id='+$route.query.p_id}">{{name}}</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>{{detailsList.name}}</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
        <div class="conter w-1350">
            <div class="text">{{detailsList.name}}</div>            
            <div class="tool-p" v-html="detailsList.content">
                图文介绍
            </div>
            
            <div class="anniu-wrap">
                <div class="anniu-p">表单下载信息：</div>
                <div class="anniu">
                    <!-- <div class="button" @click="uploadFile()">
                        <img src="../../assets/images/button.png" alt="">
                        <p>上传表格申请<i class="el-icon-upload2"></i></p>
                    </div> -->
                    <el-upload
                        class="button"     
                        name="file"               
                        :http-request="uploadFile" 
                        action="/" 
                        :limit="1"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    >
                        <div class="">
                            <img src="../../assets/images/button.png" alt="">
                            <p>上传表格申请<i class="el-icon-upload2"></i></p>
                        </div>                       
                    </el-upload>

                    <div class="button" v-for="(item,index) in detailsList.dws" :key="index" @click="goUrl(item.link_url)">
                        <img src="../../assets/images/button.png" alt="">
                        <p>{{item.title}}<i class="el-icon-download"></i></p>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CONFIG from "../../utils/config.js"
    import { getdetails,getplates  } from "../../api/index"
    import {mapState, mapMutations, mapActions} from 'vuex'  
    import axios from 'axios'
    export default {
        name:'state_list',
        data() {
            return {
                name:'',
                baseUrl:CONFIG.platextApplys,
                token:window.localStorage.getItem('token'),
                uploading:false,
                p_id:'',
                id:'',
                detailsList:[]
                
            }
        },
        computed: {
            ...mapState(['isLogin'])         
        },
        created() {
            this.id=this.$route.query.id
            this.p_id=this.$route.query.p_id
            this.getplates2()
            this.getdetails()
        },
        methods: {
            uploadFile(param) { // 文件提交
                if ( !this.isLogin) {                    
                    this.$confirm('请您先登录注册会员','温馨提示', {
                        confirmButtonText: '去登录',
                        }).then(() => {
                            this.$router.push({
                                path:'/login'
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '请登录后再操作'
                            });
                    });
                    return;
                }
                this.uploading=true;
                const formData = new FormData()
                formData.append('file', param.file) // 要提交给后台的文件,并且字段的key为Filedata
                // console.log(formData.get('file'));
                formData.append('user_id', 1)
                formData.append('platext_id', this.id)
                formData.append('api_token', this.token)
                let config = { // 添加请求头
                    headers: { 'Content-Type': 'multipart/form-data' }
                };                
                axios.post(this.baseUrl, formData, config)
                .then(res => {
                    // console.log(res);
                    if (res.data.code==200) {
                        this.uploading=false
                        // console.log("成功");
                         this.$message.success(res.data.message)
                    }
                })
            },
            //获取外贸综合服务分类
            getplates2(){
                getplates({
                    p_id:2
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        // this.fuwuList=res.data
                        for (let i = 0; i < res.data.length; i++) {
                             if (this.p_id==res.data[i].id) {
                                 this.name=res.data[i].name  
                                 return;                               
                             }
                        }
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            // 跳转外链
            goUrl(url){
                if ( !this.isLogin) {                    
                    this.$confirm('请您先登录注册会员','温馨提示', {
                        confirmButtonText: '去登录',
                        }).then(() => {
                            this.$router.push({
                                path:'/login'
                            })
                        }).catch(() => {
                            this.$message({
                                type: 'info',
                                message: '请登录后再操作'
                            });
                    });
                    return;
                }
                window.open(url) 
            },
            getdetails(){
                getdetails({
                    id:this.id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.detailsList=res.data
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{margin: 35px auto;padding-left: 15px;box-sizing: border-box;}
    .conter{
        margin: auto;margin-bottom:140px;padding:70px 100px;box-sizing: border-box;background:#fff;
        .text{font-size: 28px;color: #555555;text-align: center;}
        
        .tool-p{
            color: #666666;
            font-size: 16px;
            line-height: 32px;
            img{width: 100%;}
        }
        .anniu-wrap{
            padding-top: 40px;
            .anniu-p{
                color:#555555;
                font-size: 22px;
            }
            .anniu{
                display: flex;flex-wrap: wrap;
                .button{
                    width: 150px;height: 43px;position: relative;cursor: pointer;margin-right:2%;margin-top:30px;
                    img{width: 100%;height: 100%;}
                    p{
                        width: 100%;height: 100%;position: absolute;top: 0;left: 0;color: #0059AC;font-size: 18px;
                        display: flex;align-items:center;justify-content: center;
                        i{font-size: 25px;padding-top: 3px;}
                    }
                    &:nth-child(6n+0){margin-right: 0;}
                } 

            }
        }

    }

</style>